import { createI18n } from "vue-i18n";
import cn from "./zh-CN.json";
import zh from "./zh-TW.json";
import en from "./en-US.json";

const i18n = createI18n({
    legacy: false,
    locale: "zh-CN",
    fallbackLocale: "zh-CN",
    messages: {
      "zh-CN": cn,
      "zh-TW": zh,
      "en-US": en,
    }
  });

export default i18n
