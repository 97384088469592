<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { changeTheme } from '@/config/themeConfig';

export default {
  name: 'App',
  
  created() {
    changeTheme(process.env.VUE_APP_SITE);
  },
}
</script>
