import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: '/checkoutCounter',
    name: 'CheckoutCounter',
    hidden: true,
    component: () => import("@/views/CheckoutCounterPage.vue")
  },
  {
    path: '/404',
    hidden: true,
    component: () => import("@/views/404.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    hidden: true
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
