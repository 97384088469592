const COLOR_MAP = {
  admin: {
    headerBar: '#26463b',
    sideBar: '#26463b',
    searchBar: '#27453b',
    searchBarBtn: '#27953b',
    dialogHeader: '#1e5d42',
    tableHeader: '#1e5d42',
    menuHover: '#cfcfcf',
    color7: '#64B5F6',
  },
  opt: {
    headerBar: '#483d97',
    sideBar: '#483d97',
    searchBar: '#929292',
    searchBarBtn: '#493d87',
    dialogHeader: '#d9ab5d',
    tableHeader: '#d9ab5d',
    menuHover: '#E65100',
    color7: '#212121',
  },
  ag: {
    headerBar: '#3a3e4a',
    sideBar: '#3a3e4a',
    searchBar: '#434751',
    searchBarBtn: '#3b3e3a',
    dialogHeader: '#4e4d4e',
    tableHeader: '#4e4d4e',
    menuHover: '#9c8f4c',
    color7: '#00BFA5',
  },
  mer: {
    headerBar: '#18237d',
    sideBar: '#18237d',
    searchBar: '#17237e',
    searchBarBtn: '#19236d',
    dialogHeader: '#4d73e0',
    tableHeader: '#4d73e0',
    menuHover: '#f4f4f4',
    color7: '#00BFA5',
    checkOutHeader: '#2D6CBC',
    checkOutFooter: '#224C7E',
  },
  cash: {
    headerBar: '#827717',
    sideBar: '#827717',
    searchBar: '#E65100',
    searchBarBtn: '#828707',
    dialogHeader: '#00C853',
    tableHeader: '#00C853',
    menuHover: '#f4f4f4',
    color7: '#00BFA5',
    checkOutHeader: '#2D6CBC',
    checkOutFooter: '#224C7E',
  },
};

export const changeTheme = (site = 'admin') => {
  const themeList = Object.keys(COLOR_MAP[site]);
  themeList.forEach((v) => {
    document.body.style.setProperty(`--${v}`, COLOR_MAP[site][v]);
  });
};
