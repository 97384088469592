import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '../src/language/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

// 全局注册所有 Element Plus 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router)
app.use(i18n)
app.use(ElementPlus)

app.mount('#app')
